const servers = {
  'localhost': {
    sissi: 'http://localhost:9123/sissi/api',
    // invoicing: 'https://sissi.qs.viennaresidence.com/invoicing',
    invoicing: 'http://localhost:9124/invoicing',
    website: 'https://qs.viennaresidence.com',
  },
  'comp.vieres.net': {
    sissi: 'http://comp.vieres.net:9123/sissi/api',
    invoicing: 'http://comp.vieres.net:9124/invoicing',
    website: 'https://qs.viennaresidence.com',
  },
  'react.sissi.familia-fm.com': {
    sissi: 'https://sissi.familia-fm.com/sissi/api',
    invoicing: 'https://sissi.familia-fm.com/invoicing',
    website: 'https://web-sissi.familia-fm.com',
  },
  'react.sissi.dev.vieres.net': {
    sissi: 'https://sissi.dev.vieres.net/sissi/api',
    invoicing: 'https://sissi.dev.vieres.net/invoicing',
    website: 'https://dev.vieres.net',
  },
  'react.sissi.qs.viennaresidence.com': {
    sissi: 'https://sissi.qs.viennaresidence.com/sissi/api',
    invoicing: 'https://sissi.qs.viennaresidence.com/invoicing',
    website: 'https://qs.viennaresidence.com',
  },
  'react.sissi.viennaresidence.com': {
    sissi: 'https://sissi.viennaresidence.com/sissi/api',
    invoicing: 'https://sissi.viennaresidence.com/invoicing',
    website: 'https://viennaresidence.com',
  }
};

export function getSissiCoreUrl() {
  const server = servers[window.location.hostname];
  if (!server) {
    return '/';
  }
  return server.sissi;
}

export function getSissiInvoicingUrl() {
  const server = servers[window.location.hostname];
  if (!server) {
    return '/';
  }
  return server.invoicing;
}

export function getWebsiteUrl() {
  const server = servers[window.location.hostname];
  if (!server) {
    return '/';
  }
  return server.website;
}

export default servers;
