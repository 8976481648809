import { getSissiCoreUrl, getSissiInvoicingUrl } from '../../config/server';
import { getApartmentCategoryPrices } from 'api/apartment-category-prices';
import { getServices } from 'api/service';
import { areDatesInSameMonth, areDatesTheSameDay, nightsBetween } from '../../utils/date';

window.ids = {
  invoiceItems: 1,
};

const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DEC'];
const weekRegExp = new RegExp(/week\.(\d+)/);
const dayRegExp = new RegExp(/day\.(\d+)/);
const dayPeriodRegExp = new RegExp(/days\.\[(.+)\]/);
const INVOICE_STATUSES = {
  DRAFT: 'Draft',
  SENT: 'Sent',
  REFUNDED: 'Refunded',
  PAID: 'Paid',
};
const EDITABLE_INVOICE_STATUSES = [INVOICE_STATUSES.DRAFT];
const VISIBLE_INVOICE_STATUSES = [INVOICE_STATUSES.DRAFT, INVOICE_STATUSES.SENT, INVOICE_STATUSES.PAID];
const DEFAULT_INVOICE_ITEM = {
  service: undefined,
  contractContact: undefined,
  betweenFrom: '09:00',
  betweenTo: '17:00',
  repeatable: false,
  repeatablePeriod: 'day',
  discountMU: 'PERCENTAGE',
  description: '',
  startDate: undefined,
  endDate: undefined,
  repeatableEvery: 1,
  price: undefined,
  discount: 0,
  invoiceItemTplId: undefined
};

// The number to use for rounding prices.
// The number of ceros indicate the number of decimals of the final number.
const roundingAdjustment = 100000;

const colorsMap = {};
export const stringToColour = str => {
  const key  = str.replace(/ /g, '-').toLowerCase();
  if (colorsMap[key]) {
    return colorsMap[key];
  }

  let hash = 0;
  for (let i = 0; i < key.length; i++) {
    hash = key.charCodeAt(i) + ((hash << 5) - hash);
  }
  let c = (hash & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();
  let hex = '#' + ('00000'.substring(0, 6 - c.length) + c);
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  colorsMap[key] = 'rgba(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ', 0.55)';

  return colorsMap[key];
};

const format2Digits = (value) => {
  if (value < 10) {
    return `0${value}`;
  }
  return value;
};

const formatDate = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (!date) {
    return 'NA';
  }
  return `${format2Digits(date.getDate())}/${format2Digits(date.getMonth() + 1)}/${date.getFullYear()}`;
};

const formatDateOdoo = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return `${date.getFullYear()}-${format2Digits(date.getMonth() + 1)}-${format2Digits(date.getDate())}`;
};

const ONE_DAY = 1000 * 3600 * 24;
const FIFTEEN_DAYS = 15 * ONE_DAY;

async function getSalesCaseInvoicePlan(salesCaseId) {
  try {
    const url = `${getSissiInvoicingUrl()}/invoice-plan`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      mode:'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-service-method': 'findBySalesCaseId'
      },
      body: JSON.stringify({
        salesCaseId,
      }),
    });
    if (res.status !== 200) {
      throw await res.json();
    }
    return await res.json();
  } catch (err) {
    console.error(err);
  }
  return null;
}

async function saveSalesCaseInvoicePlanToLS(salesCase, invoicePlan) {
  const url = `${getSissiInvoicingUrl()}/invoice-plan${invoicePlan.id ? `/${invoicePlan.id}` : ''}`;
  const res = await fetch(url, {
    method: invoicePlan.id ? 'PUT' : 'POST',
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
    body: JSON.stringify({
      ...invoicePlan,
      salesCaseId: salesCase.id,
    }),
  });
  if (res.status !== 200 && res.status !== 201) {
    throw await res.json();
  }
  return await res.json();
}

export function getSalesCase(salesCaseId) {
  const url = `${getSissiCoreUrl()}/salesCaseRest/getReducedDetails/${salesCaseId}`;
  return fetch(url, { credentials: 'include' })
    .then(res => res.json())
    .catch(() => Promise.resolve(null));
}

const getInvoiceDate = (invoiceStartDate) => {
  return new Date(Math.max(invoiceStartDate.getTime() - FIFTEEN_DAYS, new Date().getTime()));
};

const getDueDate = (invoiceStartDate) => {
  return new Date(Math.max(invoiceStartDate.getTime() - ONE_DAY, new Date().getTime()));
};

function isLastDayOfMonth(date) {
  return new Date(date.getTime() + ONE_DAY).getMonth() !== date.getMonth();
}

export function getCheckInAndCheckOut(salesCase) {
  let checkin = new Date(salesCase.salesCaseCheckin);
  checkin.setHours(0);
  checkin.setMinutes(0);
  checkin.setSeconds(0);
  checkin.setMilliseconds(0);
  let checkout = new Date(salesCase.salesCaseCheckout);
  checkout.setHours(23);
  checkout.setMinutes(59);
  checkout.setSeconds(59);
  checkout.setMilliseconds(999);
  return { checkin, checkout };
}

/**
 * A full month in the invoicing system is set between the first day of a month and the first day of the following month
 * Ex: from 2024-01-01 to 2024-02-01
 */
function areDatesAFullMonth(from, to) {
  const fromDate = new Date(typeof from === 'string' ? from : from.getTime());
  fromDate.setHours(0);
  fromDate.setMinutes(0);
  fromDate.setSeconds(0);
  fromDate.setMilliseconds(0);
  const toDate = new Date(typeof to === 'string' ? to : to.getTime());
  toDate.setHours(0);
  toDate.setMinutes(0);
  toDate.setSeconds(0);
  toDate.setMilliseconds(0);

  const fromPlusAMonth = new Date(
    fromDate.setMonth(fromDate.getMonth() + 1)
  );
  return fromPlusAMonth.getTime() === toDate.getTime();
}

function generateRentalInvoice(invoiceItemTplId, from, to, dailyRate, salesCase, service, contact, apartmentCategory, invoiceIndex) {
  const isBrokenInvoice = !areDatesAFullMonth(from, to);
  const totalNights = isBrokenInvoice ? nightsBetween(from, to) : 30;
  const contractContactCompany = contact.company || { companyName: 'Private' };

  const invoiceItems = [];
  if (service) {
    const rentalPrice = totalNights * dailyRate;

    invoiceItems.push({
      id: new Date().getTime() + window.ids.invoiceItems++,
      serviceId: service.id,
      contractContactId: contact.id,
      productName: service.name,
      price: rentalPrice,
      basePrice: dailyRate,
      discount: 0,
      from,
      to,
      invoiceItemTplId,
      isRentalService: true,
      billingFrequency: service.billingFrequency,
    });
  }

  return {
    id: `${salesCase.id}-${new Date().getTime()}-${++invoiceIndex}`,
    month: months[from.getMonth()],
    year: from.getFullYear(),
    contractContactId: contact.id,
    contractContact: contact.isCompany
      ? contact.name
      : `${contact.name} @ ${contractContactCompany.companyName}`,
    invoiceDate: getInvoiceDate(from),
    dueDate: getDueDate(from),
    total: invoiceItems.reduce((total, invoiceItem) => total + invoiceItem.price, 0),
    status: INVOICE_STATUSES.DRAFT,
    apartmentCategory,
    from,
    to,
    items: invoiceItems,
    journalId: service ? service.invoicingSystemJournalId : null,
  };
}

function generateDefaultInvoiceItemData(service, contractContact, startDate, endDate, price, invoiceItemTplId = new Date().getTime()) {
  return {
    ...DEFAULT_INVOICE_ITEM,
    service,
    contractContact,
    startDate,
    endDate,
    price,
    invoiceItemTplId,
  };
}

function calculateDiscountByRules(discountRules, nights, basePrice) {
  if (!discountRules || discountRules.length === 0) {
    return 0;
  }

  const discount = discountRules.find(
    _discount => _discount.from <= nights && _discount.to >= nights
  );

  if (!discount) {
    return 0;
  }

  if (discount.mu === 'PERCENTAGE') {
    return discount.value / 100 * basePrice;
  }
  
  return discount.value;
}

function calculateSalesCaseDailyRate(salesCase, apartmentPrices) {
  let { checkin, checkout } = getCheckInAndCheckOut(salesCase);
  let createdAt = new Date(salesCase.createdAt);

  // Calculate the stay length discount
  const totalDays = nightsBetween(checkin, checkout);
  const stayLengthDiscount = calculateDiscountByRules(
    apartmentPrices.stayLengthDiscounts,
    totalDays,
    apartmentPrices.basePrice
  );

  // Calculate the last minute discount
  const daysBefore = nightsBetween(createdAt, checkin);
  const lastMinuteDiscount = calculateDiscountByRules(
    apartmentPrices.lastMinuteDiscounts,
    daysBefore,
    apartmentPrices.basePrice
  );

  // Calculate the base price by substracting the discounts to the apartmentPrices.basePrice
  const basePrice = (apartmentPrices.basePrice || 0) - stayLengthDiscount - lastMinuteDiscount;
  
  // The daily rate can not be less than 0
  return Math.max(basePrice, 0);
}

function getOrCreateSalesCaseInvoicePlan(companies, salesCaseId, recreate = false) {
  return new Promise((resolve, reject) => {
    Promise.all([
      getSalesCase(salesCaseId),
      Promise.resolve(getApartmentCategoryPrices()),
      getSalesCaseInvoicePlan(salesCaseId),
      getServices().then(res => res.data),
    ]).then(([salesCase, aptCategoryPrices, invoicePlan, services]) => {
      if (!salesCase || !salesCase.apartments || salesCase.apartments.length === 0) {
        resolve(null);
        return;
      }

      // Stop the invoice plan setup if the city of the apartment s not the one
      // in the selected company
      if (salesCase.apartments[0].cityId !== window.currentCompany.sissiCityId) {
        const company = companies.find(company => company.sissiCityId === salesCase.apartments[0].cityId);
        if (!company) {
          resolve(null);
          return;
        }

        window.localStorage.setItem('currentCompanyId', company.id);
        window.location.reload();
        return;
      }

      const aptCategoryId = salesCase.apartments[0].catId;
      const apartmentPrices = aptCategoryPrices.find(aptCategoryPrice => aptCategoryPrice.id === aptCategoryId)?.prices || {};

      if (recreate || !invoicePlan) {
        let invoiceIndex = 0;
        let { checkin, checkout } = getCheckInAndCheckOut(salesCase);

        const salesCaseCheckin = new Date(checkin.getTime())
        const totalDays = nightsBetween(checkin, checkout);
        const dailyRate = salesCase.ruBooking && salesCase.ruBooking.rentalPrice
          ? Math.round((salesCase.ruBooking.rentalPrice / totalDays) * roundingAdjustment) / roundingAdjustment
          : calculateSalesCaseDailyRate(salesCase, apartmentPrices);
        const contractContact = salesCase.contractContact || salesCase.guest;

        // Reinitialize the invoice plan
        invoicePlan = {
          baseRate: dailyRate,
          invoices: [],
        };

        if (totalDays <= 28) {
          invoicePlan.invoices.push(
            generateRentalInvoice(
              new Date().getTime() + window.ids.invoiceItems++,
              checkin,
              checkout,
              dailyRate,
              salesCase,
              apartmentPrices.RentalService,
              contractContact,
              aptCategoryId,
              ++invoiceIndex,
            )
          );
        } else {
          const invoiceItemTplId = new Date().getTime() + window.ids.invoiceItems++;

          while (checkin < checkout) {
            let _checkout = new Date(checkin.getFullYear(), checkin.getMonth() + 1, 1);
            if (_checkout.getTime() > checkout.getTime()) {
              _checkout = new Date(checkout.getTime());
            }
            if (_checkout.getTime() === checkin.getTime()) {
              break;
            }

            invoicePlan.invoices.push(
              generateRentalInvoice(
                invoiceItemTplId,
                checkin,
                _checkout,
                dailyRate,
                salesCase,
                apartmentPrices.RentalService,
                contractContact,
                aptCategoryId,
                ++invoiceIndex,
                true,
              )
            );

            checkin = new Date(checkin.getFullYear(), checkin.getMonth() + 1, 1);
          }
        }

        // Add default invoice items
        const defaultExtraServices = []
        const invoicesMap = getInvoicesMap(invoicePlan);
        const servicesWithPrices = combineServicesWithTheirPrices(services, apartmentPrices);
        
        if (salesCase.ruBooking && salesCase.ruBooking.finalCleaningPrice) {
          const service = servicesWithPrices.find(s => s.isFinalCleaningService);
          service.price = salesCase.ruBooking.finalCleaningPrice
          service && defaultExtraServices.push(service);
        }
        
        if (salesCase.ruBooking && salesCase.ruBooking.commissionPrice) {
          const service = servicesWithPrices.find(s => s.isServiceFee);
          service.price = salesCase.ruBooking.commissionPrice
          service && defaultExtraServices.push(service);
        }
        
        defaultExtraServices.forEach(service => {
          const invoiceItemData = generateDefaultInvoiceItemData(service, contractContact, salesCaseCheckin, salesCaseCheckin, service.price);
          const invoice = getInvoice(salesCase, invoicesMap, salesCaseCheckin, contractContact, service.invoicingSystemJournalId);
          addInvoiceItemToInvoice(invoice, invoiceItemData, invoiceItemData.startDate);
        });

        invoicePlan.invoices = Object.keys(invoicesMap).sort().map(key => {
          const invoice = invoicesMap[key];
          invoice.total = invoice.items.reduce((total, invoiceItem) => {
            let discount = invoiceItem.discountMU === 'PERCENTAGE'
              ? invoiceItem.discount * invoiceItem.price / 100
              : invoiceItem.discount;
            return total + invoiceItem.price - discount;
          }, 0);
          return invoice;
        });

        return saveSalesCaseInvoicePlanToLS(salesCase, invoicePlan).then(res => {
          resolve({
            salesCase,
            invoicePlan: res
          });
        });
      } else {
        invoicePlan = {
          ...invoicePlan,
          invoices: invoicePlan.invoices.map(invoice => ({
            ...invoice,
            from: new Date(invoice.from),
            to: new Date(invoice.to),
          })),
        };
        invoicePlan.invoices = invoicePlan.invoices.map(invoice => ({
          ...invoice,
          from: new Date(invoice.from),
          to: new Date(invoice.to),
        }));
        const contractContact = salesCase.contractContact || salesCase.guest;

        const lastInvoice = invoicePlan.invoices
          .filter(invoice => invoice.status !== INVOICE_STATUSES.REFUNDED)
          .sort((a, b) => {
            if (a.from.getTime() > b.from.getTime()) {
              return -1
            }
            if (a.from.getTime() < b.from.getTime()) {
              return 1
            }
            return 0;
          }).find(invoice => invoice.items.some(invoiceItem => invoiceItem.isRentalService));
        
        if (salesCase.status !== 11 || !lastInvoice || areDatesTheSameDay(lastInvoice.to, salesCase.salesCaseCheckout)) {
          return resolve({
            salesCase,
            invoicePlan
          });
        }

        const invoiceItemTplId = new Date().getTime() + window.ids.invoiceItems++;
        let _checkin = lastInvoice.to;
        const checkin = new Date(salesCase.salesCaseCheckin);
        const checkout = new Date(salesCase.salesCaseCheckout);
        const salesCaseNights = nightsBetween(salesCase.salesCaseCheckin, salesCase.salesCaseCheckout);

        const rentalItem = lastInvoice.items.find(ii => ii.isRentalService);
        const dailyRate = salesCase.extensionBaseRate;
        
        if (lastInvoice.status === INVOICE_STATUSES.DRAFT && rentalItem.basePrice === salesCase.extensionBaseRate) {
          const shouldResetLastInvoiceEndDate = salesCaseNights > 28 &&
            invoicePlan.totalNights <= 28 &&
            !areDatesInSameMonth(checkin, checkout);
          let _checkout = new Date(
            _checkin.getFullYear(),
            _checkin.getMonth() + (shouldResetLastInvoiceEndDate ? 0 : 1),
            1,
          );
          if (_checkout.getTime() > checkout.getTime() || salesCaseNights <= 28) {
            _checkout = new Date(checkout.getTime());
          }

          const isBrokenInvoice = !areDatesAFullMonth(lastInvoice.from, _checkout);
          const invoiceDays = isBrokenInvoice
            ? nightsBetween(lastInvoice.from, _checkout)
            : 30;

          const price = dailyRate * invoiceDays;
          
          lastInvoice.items = lastInvoice.items.map(invoiceItem => ({
            ...invoiceItem,
            to: _checkout,
            price: price,
            basePrice: salesCase.extensionBaseRate,
          }));
          lastInvoice.to = _checkout
          lastInvoice.total = lastInvoice.items.reduce((total, invoiceItem) => {
            let discount = invoiceItem.discountMU === 'PERCENTAGE'
              ? invoiceItem.discount * invoiceItem.price / 100
              : invoiceItem.discount;
            return total + invoiceItem.price - discount;
          }, 0);
          _checkin = new Date(_checkout.getTime());
        }

        let invoiceIndex = 0;
        while (_checkin < checkout) {
          let _checkout = new Date(_checkin.getFullYear(), _checkin.getMonth() + 1, 1);
          if (_checkout.getTime() > checkout.getTime()) {
            _checkout = new Date(checkout.getTime());
          }
          if (_checkout.getTime() === checkin.getTime()) {
            break;
          }

          invoicePlan.invoices.push(
            generateRentalInvoice(
              invoiceItemTplId,
              _checkin,
              _checkout,
              dailyRate,
              salesCase,
              apartmentPrices.RentalService,
              contractContact,
              aptCategoryId,
              ++invoiceIndex,
              true,
            )
          );

          // Update the base rate of all the rentals
          invoicePlan.invoices = invoicePlan.invoices.map(invoice => ({
            ...invoice,
            items: invoice.items.map(invoiceItem => ({
              ...invoiceItem,
              ...(invoiceItem.isRentalService ? { basePrice: salesCase.extensionBaseRate } : {})
            }))
          }))

          _checkin = new Date(_checkin.getFullYear(), _checkin.getMonth() + 1, 1);
        }

        return saveSalesCaseInvoicePlanToLS(salesCase, invoicePlan).then(res => {
          resolve({
            salesCase,
            invoicePlan: res
          });
        });
      }
    });
  });
}

function getInvoicesMap(invoicePlan) {
  const invoicesMap = invoicePlan.invoices.reduce((result, invoice) => {
    const _result = { ...result };
    const from = new Date(invoice.from);

    let invoiceKey = `${from.getFullYear()}-${from.getMonth() + 1}-${invoice.contractContactId}-${invoice.journalId}-${invoice.status}`;
    if (invoice.status !== INVOICE_STATUSES.DRAFT) {
      invoiceKey = `${invoiceKey}-${invoice.id}`;
    }

    _result[invoiceKey] = invoice;
    return _result;
  }, {});
  return invoicesMap;
}

function getInvoice(salesCase, invoiceMap, date, contact, journalId = null) {
  const { checkin, checkout } = getCheckInAndCheckOut(salesCase);
  const totalDays = Math.floor((checkout.getTime() - checkin.getTime()) / ONE_DAY);

  const key = totalDays >= 28
    ? `${date.getFullYear()}-${date.getMonth() + 1}-${contact.id}-${journalId}-${INVOICE_STATUSES.DRAFT}`
    : `${checkin.getFullYear()}-${checkin.getMonth() + 1}-${contact.id}-${journalId}-${INVOICE_STATUSES.DRAFT}`;
  if (invoiceMap[key] && invoiceMap[key].status === INVOICE_STATUSES.DRAFT) {
    return invoiceMap[key];
  }

  let monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
  let monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const contractContactCompany = contact.company || { companyName: 'Private' };

  const invoiceFrom = totalDays >= 28
    ? new Date(monthStart.getTime())
    : checkin;
  let invoiceTo = totalDays >= 28
    ? new Date(Math.min(checkout.getTime(), monthEnd.getTime()))
    : checkout;
  invoiceTo = invoiceTo.getTime() !== checkout.getTime()
    ? new Date(invoiceTo.getFullYear(), invoiceTo.getMonth(), invoiceTo.getDate() + 1)
    : checkout;

  const invoice = {
    id: `${new Date().getTime()}-${Object.keys(invoiceMap).length + 1}`,
    month: months[invoiceFrom.getMonth()],
    year: invoiceFrom.getFullYear(),
    contractContactId: contact.id,
    contractContact: contact.companyName
      ? contact.companyName
      : `${contact.name} @ ${contractContactCompany.companyName}`,
    invoiceDate: getInvoiceDate(invoiceFrom),
    dueDate: getDueDate(invoiceFrom),
    from: invoiceFrom,
    to: invoiceTo,
    total: 0,
    status: INVOICE_STATUSES.DRAFT,
    apartmentCategory: salesCase.apartments[0].catId,
    items: [],
    journalId,
  };
  invoiceMap[key] = invoice;
  return invoice;
}

function calculatePrice(service, from, to, basePrice) {
  if (service.billingFrequency !== 'MONTHLY') {
    return basePrice;
  }

  if (typeof from === 'string') {
    from = new Date(from);
  }

  if (typeof to === 'string') {
    to = new Date(to);
  }

  const totalDays = nightsBetween(from, to);
  const isBrokenInvoice = !areDatesAFullMonth(from, to);
  return isBrokenInvoice
    ? Math.round(basePrice / 30 * totalDays * 100) / 100
    : basePrice;
}

function addInvoiceItemToInvoice(invoice, invoiceItemData, date) {
  const {
    service,
    betweenFrom,
    betweenTo,
    contractContact,
    price: basePrice,
    discount,
    discountMU,
    description,
    invoiceItemTplId,
  } = invoiceItemData;

  // Get date objects if the invoice dates are strings
  const invoiceFromDate = typeof invoice.from === 'string' ? new Date(invoice.from) : invoice.from;
  const invoiceToDate = typeof invoice.to === 'string' ? new Date(invoice.to) : invoice.to;

  const from = service.billingFrequency === 'MONTHLY'
    ? new Date(
        Math.max(
          invoiceFromDate.getTime(),
          date.getTime(),
        )
      )
    : date;
  const to = service.billingFrequency === 'MONTHLY'
    ? invoiceToDate
    : date;

  invoice.items.push({
    id: new Date().getTime() + window.ids.invoiceItems++,
    serviceId: service.id,
    contractContactId: contractContact.id,
    productName: service.name,
    price: calculatePrice(service, from, to, basePrice),
    basePrice,
    from,
    to,
    betweenFrom,
    betweenTo,
    discount,
    discountMU,
    description,
    invoiceItemTplId,
    billingFrequency: service.billingFrequency,
  });
}

function validateMaxDate(date, maxDate) {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (typeof maxDate === 'string') {
    maxDate = new Date(maxDate);
  }
  return date.getTime() <= maxDate.getTime() ? date : undefined;
}

function getNextDate(date, invoiceItemData, maxDate) {
  const {
    repeatableEvery,
    repeatablePeriod,
    repeatableOn,
  } = invoiceItemData;
  if (!date && repeatablePeriod !== 'week') {
    return invoiceItemData.startDate;
  }
  if (repeatablePeriod === 'day') {
    return validateMaxDate(
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + repeatableEvery,
      ),
      maxDate,
    );
  }
  if (repeatablePeriod === 'month') {
    if (!repeatableOn) {
      return validateMaxDate(
        new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          1,
        ),
        maxDate,
      );
    }

    const dateNumber = parseInt(dayRegExp.exec(repeatableOn)[1]);
    if (repeatableOn.indexOf('day') === 0) {
      return validateMaxDate(
        new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          dateNumber,
        ),
        maxDate,
      );
    }

    let newDate;
    const weekNumber = parseInt(weekRegExp.exec(repeatableOn)[1]);
    let firstDayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1,
    );
    while (firstDayOfMonth.getTime() < maxDate.getTime()) {
      newDate = new Date(
        firstDayOfMonth.getFullYear(),
        firstDayOfMonth.getMonth(),
        1 - firstDayOfMonth.getDay() + ((weekNumber - 1) * repeatableEvery) + dateNumber
      );

      if (newDate.getMonth() === firstDayOfMonth.getMonth()) {
        return newDate;
      }

      firstDayOfMonth = new Date(
        firstDayOfMonth.getFullYear(),
        firstDayOfMonth.getMonth() + 1,
        1,
      );
    }
  }
  if (repeatablePeriod === 'week') {
    const selectedWeekDays = dayPeriodRegExp
      .exec(repeatableOn)[1]
      .split(',')
      .map(test => parseInt(test));

    if (!date) {
      if (selectedWeekDays.includes(invoiceItemData.startDate.getDay())) {
        return invoiceItemData.startDate;
      }
      date = invoiceItemData.startDate;
    }

    const nextWeekDay = selectedWeekDays.find(weekDay => weekDay > date.getDay());
    if (nextWeekDay) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + (nextWeekDay - date.getDay()),
      );
    }

    return validateMaxDate(
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay() + (7 * repeatableEvery) + selectedWeekDays[0],
      ),
      maxDate,
    );
  }
  return undefined;
}

async function addInvoiceItem(salesCase, invoiceItemData) {
  // Add the invoice item template id to the invoice item
  invoiceItemData.invoiceItemTplId = new Date().getTime();

  // Proceed to add the invoice item
  const {
    contractContact,
    startDate,
    endDate,
    repeatable,
  } = invoiceItemData;

  const scInvoicePlan = await getSalesCaseInvoicePlan(salesCase.id);
  const invoicesMap = getInvoicesMap(scInvoicePlan);
  if (!repeatable) {
    const invoice = getInvoice(salesCase, invoicesMap, startDate, contractContact, invoiceItemData.service.invoicingSystemJournalId);
    addInvoiceItemToInvoice(invoice, invoiceItemData, invoiceItemData.startDate);
  } else {
    let date = null;
    let max = 1000;
    while ((!date || date.getTime() < endDate.getTime()) && max-- > 0) {
      date = getNextDate(date, invoiceItemData, endDate);
      if (
        !date ||
        (
          date.getTime() > endDate.getTime() ||
          (
            invoiceItemData.service.billingFrequency === 'MONTHLY' &&
            date.getTime() === endDate.getTime()
          )
        )
      ) {
        break;
      }
      const invoice = getInvoice(salesCase, invoicesMap, date, contractContact, invoiceItemData.service.invoicingSystemJournalId);
      addInvoiceItemToInvoice(invoice, invoiceItemData, date);
    }
  }
  const invoicePlanInvoices = Object.keys(invoicesMap).sort().map(key => {
    const invoice = invoicesMap[key];
    invoice.total = invoice.items.reduce((total, invoiceItem) => {
      let discount = invoiceItem.discountMU === 'PERCENTAGE'
        ? invoiceItem.discount * invoiceItem.price / 100
        : invoiceItem.discount;
      return total + invoiceItem.price - discount;
    }, 0);
    return invoice;
  });

  return await saveSalesCaseInvoicePlanToLS(salesCase, {
    ...scInvoicePlan,
    invoices: invoicePlanInvoices,
  });
}

function isDateMatch(date1, date2, exact) {
  if (typeof date1 === 'string') {
    date1 = new Date(date1);
  }
  if (typeof date2 === 'string') {
    date2 = new Date(date2);
  }
  return exact
    ? date1.getTime() === date2.getTime()
    : date1.getTime() >= date2.getTime();
}

function countInvoicePlanItemsByService(invoicePlan, service) {
  let count = 0;
  invoicePlan.invoices.forEach(invoice => {
    invoice.items.forEach(currentInvoiceItem => {
      if (currentInvoiceItem.serviceId === service.id) {
        count ++;
      }
    });
  });
  return count;
}

async function updateInvoiceItem(salesCase, invoiceItemData) {
  const invoicePlan = await getSalesCaseInvoicePlan(salesCase.id);
  const {
    invoiceItem,
    newData: {
      service,
      ...newData
    },
    changeRemainingItems
  } = invoiceItemData;

  if (!(invoiceItem.from instanceof Date)) {
    invoiceItem.from = new Date(invoiceItem.from);
  }

  if (!changeRemainingItems) {
    newData.from = newData.startDate;
    newData.to = newData.endDate;
  }
  delete newData.startDate;
  delete newData.endDate;

  const countOfInvoicePlanLinesWithService = countInvoicePlanItemsByService(
    invoicePlan,
    service,
  );

  const { checkin, checkout } = getCheckInAndCheckOut(salesCase);
  const totalSalesCasesNights = nightsBetween(checkin, checkout);

  const newInvoicePlanInvoices = invoicePlan.invoices.map(invoice => {
    const items = invoice.items.map(currentInvoiceItem => {

      const hasSameId = currentInvoiceItem.id === invoiceItem.id;
      const areDatesAMatch = isDateMatch(currentInvoiceItem.from, invoiceItem.from, !changeRemainingItems);
      const hasSameTemplate = currentInvoiceItem.invoiceItemTplId === invoiceItem.invoiceItemTplId;

      if ((!changeRemainingItems && !hasSameId) || (changeRemainingItems && (!areDatesAMatch || !hasSameTemplate))) {
        return currentInvoiceItem;
      }

      let newInvoiceItem = {
        ...currentInvoiceItem,
        ...newData,
      };

      // Calculate the price for service when the sales case is larger than 28 nights
      if (changeRemainingItems && totalSalesCasesNights >= 28) {
        newInvoiceItem.price = calculatePrice(
          service,
          currentInvoiceItem.from,
          currentInvoiceItem.to,
          newData.price,
        );
      }

      const invoiceItemNights = totalSalesCasesNights < 28
        ? nightsBetween(newInvoiceItem.from, newInvoiceItem.to)
        : 30;

      // Set the base price if there is only one invoice or all remaining items should be changed
      if (countOfInvoicePlanLinesWithService === 1 || changeRemainingItems) {
        newInvoiceItem.basePrice = invoiceItem.isRentalService
          ? Math.floor(newData.price / invoiceItemNights * roundingAdjustment) / roundingAdjustment
          : newData.price;
      }

      return newInvoiceItem;
    });

    return {
      ...invoice,
      items,
    };
  });

  return saveSalesCaseInvoicePlanToLS(salesCase, {
    ...invoicePlan,
    invoices: newInvoicePlanInvoices,
  });
}

async function deleteInvoiceItem(salesCase, invoiceItemData) {
  const invoicePlan = await getSalesCaseInvoicePlan(salesCase.id);
  const {
    invoiceItem,
    changeRemainingItems
  } = invoiceItemData;
  if (!(invoiceItem.from instanceof Date)) {
    invoiceItem.from = new Date(invoiceItem.from);
  }

  const newInvoicePlanInvoices = invoicePlan.invoices.map(invoice => {
    const items = invoice.items.filter(currentInvoiceItem => {
      const datesAreAMatch = isDateMatch(currentInvoiceItem.from, invoiceItem.from, !changeRemainingItems);
      const hasSameTemplate = currentInvoiceItem.invoiceItemTplId === invoiceItem.invoiceItemTplId;
      return !hasSameTemplate || !datesAreAMatch;
    });
    if (!items.length) {
      return null;
    }
    return {
      ...invoice,
      items,
    };
  }).filter(invoice => Boolean(invoice));

  return saveSalesCaseInvoicePlanToLS(salesCase, {
    ...invoicePlan,
    invoices: newInvoicePlanInvoices,
  });
}

function getServicesForApartmentCategory(catId) {
  return Promise
    .all([getServices(), getApartmentCategoryPrices()])
    .then(([servicesResponse, categoriesPrices]) => {
      const catPrices = categoriesPrices.find(apartmentCategoryPrices => apartmentCategoryPrices.id === catId);
      // There are ano prices for the apartment yet
      if (!catPrices) {
        return [];
      }
      return combineServicesWithTheirPrices(servicesResponse.data, catPrices.prices);
    });
}

function combineServicesWithTheirPrices(services, catPrices) {
  const servicePrices = services.reduce((result, service) => {
    const price = (catPrices.servicesPrices || []).find(sp => sp.serviceId === service.id);
    if (!price) {
      return result;
    }
    service.price = price.value;
    return [...result, service];
  }, []);
  if (catPrices.RentalService) {
    servicePrices.push(catPrices.RentalService);
  }
  return servicePrices;
}

function getRentalServiceForApartmentCategory(catId) {
  const prices = getApartmentCategoryPrices();
  const catPrices = prices[catId];

  const rentalService = {
    billingFrequency: 'MONTHLY',
    description: 'Apartment Rental',
    id: 'apartment-rental',
    name: 'Apartment Rental',
    price: 0,
    priceStrategy: 'FIXED',
    secured: true,
  };

  // There are ano prices for the apartment yet
  if (!catPrices) {
    return rentalService;
  }

  return {
    ...rentalService,
    price: catPrices.basePrice * 30,
  };
}

function filterContacts(filter) {
  const url = `${getSissiCoreUrl()}/customer/autocomplete?query=${filter}&onlyWithAddress=true`;
  return fetch(
    url,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res => res.json())
    .catch(() => Promise.resolve([]));
}

function getContactById(id) {
  const url = `${getSissiCoreUrl()}/contact/get?id=${id}`;
  return fetch(
    url,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res => res.json())
    .catch(() => Promise.resolve(null));
}

function calculateInvoiceTotal(invoice) {
  let total = 0;
  invoice.items.forEach(invoiceItem => {
    const discount = invoiceItem.discountMU === 'PERCENTAGE'
      ? invoiceItem.price * invoiceItem.discount / 100
      : invoiceItem.discount;
    total += invoiceItem.price - discount;
  });
  return total;
}

async function createAndSendInvoice(salesCase, invoice) {
  try {
    const url = `${getSissiInvoicingUrl()}/invoice-plan`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      mode:'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-service-method': 'sendInvoice'
      },
      body: JSON.stringify({
        salesCaseId: salesCase.id,
        invoiceId: invoice.id
      }),
    });
    if (res.status !== 200) {
      throw await res.json();
    }
    return await res.json();
  } catch (err) {
    console.error(err);
  }
  return null;
}

async function refundInvoice(salesCase, invoice) {
  try {
    const url = `${getSissiInvoicingUrl()}/invoice-plan`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      mode:'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-service-method': 'refundInvoice'
      },
      body: JSON.stringify({
        salesCaseId: salesCase.id,
        invoiceId: invoice.id
      }),
    });
    if (res.status !== 200) {
      throw await res.json();
    }
    return await res.json();
  } catch (err) {
    console.error(err);
  }
  return null;
}

async function syncInvoicesStatuses(salesCase) {
  try {
    const url = `${getSissiInvoicingUrl()}/invoice-plan`;
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      mode:'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-service-method': 'syncSalesCaseInvoices'
      },
      body: JSON.stringify({
        salesCaseId: salesCase.id,
      }),
    });
    if (res.status !== 200) {
      throw await res.json();
    }
    return await res.json();
  } catch (err) {
    console.error(err);
  }
  return null;
}

const isInvoiceEditable = invoice => {
  return EDITABLE_INVOICE_STATUSES.includes(invoice.status);
}

const isInvoiceVisible = invoice => {
  return VISIBLE_INVOICE_STATUSES.includes(invoice.status);
}

const sortInvoices = (inv1, inv2) => {
  if (inv1.from < inv2.from) {
    return -1;
  }
  if (inv1.from > inv2.from) {
    return 1;
  }
  return 0;
}

export {
  INVOICE_STATUSES,
  formatDate,
  formatDateOdoo,
  getOrCreateSalesCaseInvoicePlan,
  filterContacts,
  getContactById,
  getServicesForApartmentCategory,
  getRentalServiceForApartmentCategory,
  addInvoiceItem,
  updateInvoiceItem,
  deleteInvoiceItem,
  calculateInvoiceTotal,
  syncInvoicesStatuses,
  createAndSendInvoice,
  refundInvoice,
  isInvoiceEditable,
  isInvoiceVisible,
  sortInvoices,
};
